import { BaseComponent } from '../../atoms/BaseComponent';

class CurveP extends BaseComponent {
    getCurve() {
        return [
            [176, 0],
            [201, -49],

            [192, -80],
            [-38, -168],

            [-400, -368],
            [-472, -224],

            [-56, 112],
            [648, 240],

            [712, 112]
        ]
    }

    getPath() {
        const coef = this.state.coef;
        const offset = this.state.offset;
        const circleMod = this.state.circleMod;
        return `
            M 0 ${this.state.sp}

            h ${480 * coef}

            c
                ${this.getCurve().map((t, i) => {
                    if (i !== 4 && i !== 5) {
                        return `${t[0] * coef}, ${t[1] * coef}`
                    }
                    return `${t[0] * coef + circleMod}, ${t[1] + circleMod}`
                }).join(' ')}

            S ${472 * coef} 10 ${472 * coef} 10


            h ${748 * coef}
        `;
    }

    setState(state = {}) {
        this.state = Object.assign({}, this.state, state)
        console.log("STATE", Date.now(), this.state)
        this.render();
    }

    onResize() {
        const WinW = 1184;
        const actualW = document.documentElement.clientWidth;
        let coef = Math.min(actualW / WinW, 1);
        let offset = Math.abs(Math.min(0, actualW - WinW))/2;
        let sp = this.parentElement.clientHeight - (321 - (23 + 24));
        let stW = 64;
        let circleMod = 0;

        if (actualW < 800) {
            const topPadding = 23 + 28;
            const bottomPadding = 96 + 56 + 25;
            coef *= 1.2;
            offset = (50 * coef - 50);
            sp = this.parentElement.clientHeight - bottomPadding - topPadding ;
            stW = 50;
            circleMod = 10;
        }


        this.setState({
            coef,
            offset,
            sp,
            stW,
            circleMod,
            firstTimeRender: false
        })
    }

    render() {
        this.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="1120" height="736" viewBox="0 0 1120 736">
                <path class = "${this.state.firstTimeRender ? 's-path' : ''}" fill="none" fill-rule="evenodd" stroke="red" stroke-width = "${this.state.stW}"
                    d="${this.getPath()}"/>
            </svg>
        `
    }

    connectedCallback() {
        this.state = {
            coef: 1,
            offset: 0,
            sp: 512,
            stW: 64,
            firstTimeRender: true,
            circleMod: 0
        };
        this.onResize();
        this.state.firstTimeRender = true;
        this.render();
        window.addEventListener('resize', () => this.onResize())
    }
}


customElements.define('curve-p', CurveP);
